import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { between } from 'polished';

import Page from '../components/page/page';
import HeaderSection from '../components/header-section';
import HeaderFront from '../components/header-front';
import Navigation from '../components/navigation';
import HeaderHeading from '../components/header-heading';
import HeaderDescription from '../components/header-description';
import SEO from '../components/seo';
import Container from '../components/container';

const StyledHeaderSection = styled(HeaderSection)`
	min-height: ${between('280px', '470px')};
`;

const Sets = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
	align-items: baseline;
	grid-gap: ${between('5px', '60px')};

	div {
		h3 {
			font-size: 22px;
		}

		ul {
			list-style-type: none;
			margin-top: 3px;

			li {
				line-height: 28px;
			}
		}
	}
`;

const TownsByLetters = ({ list }) => {
	const reduced = list.reduce((acc, { node }) => {
		let letter = node.label_nominative[0].toLocaleUpperCase();

		if (acc[letter]) acc[letter].push(node);
		else acc[letter] = [node];

		return acc;
	}, []);

	const chunks = [];
	for (const group in reduced) chunks.push(reduced[group]);

	return (
		<Sets>
			{chunks.map((towns, index) => (
				<div key={index}>
					<h3>{towns[0].label_nominative[0].toLocaleUpperCase()}</h3>
					<ul>
						{towns.map((town, i) => (
							<li key={i}><Link to={`/${town.slug}/`} title={'Usługi weselne ' + town.label_in_area}>{town.label_nominative}</Link></li>
						))}
					</ul>
				</div>
			))}
		</Sets>
	);
};

const TownsPage = () => (
	<Page>
		<SEO title="Lista miast" description="Sprawdź katalog usług weselnych w Twoim mieście" canonical="miasta" />
		<StyledHeaderSection hero="/hero-towns.jpg">
			<HeaderFront>
				<Navigation />
				<Container>
					<HeaderHeading>Lista miast</HeaderHeading>
					<HeaderDescription>Sprawdź katalog usług weselnych w Twoim mieście</HeaderDescription>
				</Container>
			</HeaderFront>
		</StyledHeaderSection>

		<Container>
			<StaticQuery
				query={graphql`
					query TownsPage {
						allMysqlTown(sort: {fields: label_nominative, order: ASC}) {
							edges {
								node {
									slug
									label_nominative
									label_in_area
								}
							}
						}
					}
				`}
				render={data => <TownsByLetters list={data.allMysqlTown.edges} />}
			/>
		</Container>
	</Page>
);

export default TownsPage;
